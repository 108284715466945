import { Component, OnDestroy } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Subject } from "rxjs";

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnDestroy{
  destroy$: Subject<void> = new Subject();
  protected sanitizer: DomSanitizer;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSanitizedHtml(html: string): SafeHtml {
    if (this.sanitizer) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return html;
  }

  trackByItemUuid(index: number, item: any) {
    return item?.uuid;
  }
}
