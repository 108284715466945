import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebService } from 'src/app/services/web.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss'],
})
export class PageSidebarComponent implements OnInit {
  @Input() navitem: any;
  profileDate: any = {};
  constructor(private sharedService: SharedService,
    public router: Router,
    private webService: WebService,
    private titleService: Title,
    private spinnerService: NgxSpinnerService,
    public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    console.log('navitem', this.navitem);
    this.sharedService.sharedProfileData.subscribe((data: any) => {
      console.log({ data });
      this.profileDate = data;
      if(this.profileDate && Object.keys(this.profileDate).length===0){
          this.getProfile();
      }
    });
  }

  getProfile() {
    // Not using spinner show/ hide here since it messes with the spinner shown/ hidden by 
    // the data loader called by the component which actually serves the page
    // this.spinnerService.show();

    let url = `cpanel-api/users/profile`;
    this.webService.get(url).subscribe(
      (response: any) => {
        // this.spinnerService.hide();
        this.profileDate.img=response?.thumbnailUrl;
        this.profileDate.name=response?.authorName;
        this.profileDate.speciality=response?.speciality[0]?.name;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  parentNavigate(item:any){
    if(item.url){
      this.router.navigate( [item.url]);
    }

  }
}
