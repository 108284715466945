import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = [
    {
      name: 'Accounts',
      url: '',
      icon: 'account_box',
      children: [
        {
          name: 'Infocenters',
          url: '/infocenter',
          icon: 'info',
        }
      ],
    },
    {
      name: 'Content',
      url: '',
      icon: 'content_paste',
      children: [
        {
          name: 'Dashboard',
          url: '/content/dashboard',
          icon: 'dashboard',
        },
        {
          name: 'Drafts',
          url: '/content/drafts',
          icon: 'dashboard',
        },
        {
          name: 'Polls',
          url: '/content/polls',
          icon: 'dashboard',
        },
        {
          name: 'FAQs',
          url: '/content/faqs',
          icon: 'dashboard',
        },
        {
          name: 'Tags',
          url: '/content/tags',
          icon: 'dashboard',
        },
        // {
        //   name: 'Content Search',
        //   url: '/content/post-monitor',
        //   icon: 'postmonitor',
        // },
        {
          name: 'A.CME',
          url: '/content/acme',
          icon: 'acme',
        },
        {
          name: 'Webinar',
          url: '/content/list-webinars',
          icon: 'webinar',
        },
        {
          name: 'Open Webinar',
          url: '/content/open-webinars/list',
          icon: 'webinar',
        },
        {
          name: 'SuggestionChips',
          url: '/content/suggestion-chips',
          icon: 'dashboard',
        },
        {
          name: 'Guidelines',
          url: '/content/guidelines',
          icon: 'dashboard',
        },
        {
          name: 'Specialities',
          url: '/content/specialities',
          icon: 'dashboard',
        },
      ],
    },
    {
      name: 'Curation',
      url: '',
      icon: 'poll',
      children: [
        {
          name: 'Stories',
          url: '/content/stories',
          icon: 'stories',
        },
        {
          name: 'Announcements',
          url: '/content/announcements',
          icon: 'announcements',
        },
        {
          name: 'Shorts',
          url: '/content/shorts',
          icon: 'shorts',
        },
        {
          name: 'Notifications',
          url: '/content/notifications',
          icon: 'notifications',
        }
      ],
    },
    {
      name: 'Campaigns',
      url: '',
      icon: 'campaign',
      children: [
        {
          name: 'History',
          url: '/campaigns/history',
          icon: 'history',
        },
        {
          name: 'Open Campaigns',
          url: '/campaigns/open-campaigns',
          icon: 'open-campaigns',
        },
        // {
        //   name: 'Scheduled',
        //   url: '/campaigns/scheduled',0
        //   icon: 'scheduled',
        // },
        {
          name: 'Ticker Update',
          url: '/campaigns/ticker-update',
          icon: 'ticker-update',
        },
        {
          name: 'Create new campaign',
          url: '/campaigns/campaign-processor',
          icon: 'campaign-processor',
        },
        {
          name: 'Create new campaign V2',
          url: '/campaigns/create-campaign-v2',
          icon: 'campaign-processor',
        },
      ],
    },
    {
      name: 'Community',
      url: '',
      icon: 'admin_panel_settings',
      children: [
        {
          name: 'User Management',
          url: '/community',
          icon: 'role',
        },
        {
          name: 'Login Trails',
          url: '/login-trails',
          icon: 'role',
        }
      ],
    },
    {
      name: 'Reports',
      url: '',
      icon: 'analytics',
      children: [
        {
          name: 'Viewership Report',
          url: '/reports/viewership-report',
          icon: 'role',
        },
        {
          name: 'Infocenter Report',
          url: '/reports/infocenter-report',
          icon: 'role',
        },
        {
          name: 'Geo location wise reports',
          url: '/reports/geo-location-wise-reports',
          icon: 'role',
        },
        {
          name: 'Specialities',
          url: '/content/specialities',
          icon: 'specialities',
        },
        {
          name: 'Poll Statistics',
          url: '/content/pollstatistics',
          icon: 'pollstatistics',
        },
      ],
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
