<app-page-sidebar [navitem]="navItems"></app-page-sidebar>

<app-page-header></app-page-header>
<div class="content">
  <div class="content-body flow-rootX3">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
    <router-outlet></router-outlet>
  </div><!-- content-body -->
</div><!-- content -->
<app-page-footer></app-page-footer>
