import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-thumbnail-view',
  templateUrl: './thumbnail-view.component.html',
  styleUrls: ['./thumbnail-view.component.scss']
})
export class ThumbnailViewComponent implements OnInit {

  @Input() post: any;
  @Input() showVerticalThumbnail: boolean;
  @Input() showHorizontalThumbnail: boolean;
  // @Input() imageHeight: Number = 354;

  vimeoVideoList: any = [];
  mp4VideoList: any = [];
  youTubeVideoList: any = [];
  contentThumbnailList: any = [];
  horizontalThumbnailList: any = [];
  verticalThumbnailList: any = [];
  contentDocumentList: any = [];
  postData: any;
  pdfUrl: any;
  videoEmbedUrl: any;
  videoSourceUrl: any;
  infographicCoverImg: any = [];
  infographicCarousel: any = [];
  sourceUrl: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void { }

  ngOnChanges() {
    this.getThumbnail(this.post);
  }

  getThumbnail(post: any) {
    //multimedia length > 1 and multiple thubnails or video or PDF
    if (post.multimedia != null && post.multimedia.length > 1) {
      for (var i = 0; i < post.multimedia.length; i++) {
        if (
          (post.multimedia[i]?.contentType === 'image/jpg' ||
            post.multimedia[i]?.contentType === 'image/png' ||
            post?.multimedia[i].contentType === 'image/jpeg') &&
          post?.multimedia[i].type !== 'carousel'
        ) {
          if(post.multimedia[i]?.width == null || post.multimedia[i]?.height) {
            this.horizontalThumbnailList.push(post.multimedia[i]);
          } else if (post.multimedia[i]?.width < post.multimedia[i]?.height) {
            //vertical
            this.verticalThumbnailList = this.verticalThumbnailList || [];
            this.verticalThumbnailList.push(post.multimedia[i]);
          } else if (post.multimedia[i]?.width > post.multimedia[i]?.height) {
            //horizontal
            this.horizontalThumbnailList.push(post.multimedia[i]);
          } else {
            //square
            this.horizontalThumbnailList.push(post.multimedia[i]);
          }
        }
      }
    }
    
    this.postData = JSON.stringify(post);
    var postDetail = JSON.parse(this.postData);
  }

  getTrustedUrl(linkedVideo: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.getAutoplayLink(linkedVideo)
    );
  }

  get_url(data: any) {
    return data.split('.').slice(0, -1).join('.');
  }

  get_url_extension(url: any) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  test(data: any) {
    var a = document.createElement('a');
    a.href = data;
    return a.hostname;
  }

  getAutoplayLink(imgLink: string): string {
    if (imgLink?.includes('?')) {
      return imgLink + '&autoplay=1';
    }
    return imgLink + '?autoplay=1';
  }
}
