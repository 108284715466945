import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SharedService {
  private receivedPageTitle = new BehaviorSubject<Object>({});
  private receivedProfileData = new BehaviorSubject<Object>({});
  sharedPageTitle = this.receivedPageTitle.asObservable();
  sharedProfileData = this.receivedProfileData.asObservable();

  constructor(private httpClient: HttpClient) {}

  updatePageTitle(data: any) {
    this.receivedPageTitle.next(data);
  }

  updateProfileData(data: any) {
    this.receivedProfileData.next(data);
  }

  getCountryList(): Observable<Object> {
    return this.httpClient.get(
      environment.API_ENDPOINT + 'cpanel-api/get-countries',
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }
  
  getCities() {
    return this.httpClient.get(environment.API_ENDPOINT + 'api/v2/cities', {
      withCredentials: true,
    });
  }

  getThetapeutics(): Observable<Object> {
    return this.httpClient.get(
      environment.API_ENDPOINT + 'cpanel-api/get-therapeutic-area',
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  getUserList(userFilterObj: any, offset: any, limit: any) {
    return this.httpClient.post(environment.API_ENDPOINT+ 'cpanel-api/get-users?offset='+offset+'&limit='+limit, userFilterObj,{
      withCredentials: true,
      responseType: 'json'
    });
  }

  calculatePagesCount(pageSize: number, totalCount: number) {
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  }

  getUserCountryList(): Observable<Object> {
    return this.httpClient.get(
      environment.API_ENDPOINT + 'cpanel-api/distinct-countries',
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  getUserStateList(filterPickerObj: any): Observable<Object> {
    return this.httpClient.post(
      environment.API_ENDPOINT + `cpanel-api/countries/states`, filterPickerObj,
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  getStateListByZone(filterPickerObj: any): Observable<Object> {
    return this.httpClient.post(
      environment.API_ENDPOINT + 'cpanel-api/zone/states', filterPickerObj,
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  getUserCityList(filterPickerObj: any): Observable<Object> {
    return this.httpClient.post(
      environment.API_ENDPOINT + `cpanel-api/states/cities`, filterPickerObj,
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

}
