<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <div class="text-center mb-4">
                        <img
                            src="./assets/img/brand/logo-lg.png"
                            height="80"
                        >
                    </div>
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form
                                    class="full_frm"
                                    [formGroup]="loginForm"
                                    (ngSubmit)="login()"
                                >
                                    <div class="text-center">

                                        <h2>Login</h2>
                                        <p class="text-muted">Sign In to your account</p>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="icon-user"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="email"
                                            formControlName="email"
                                            id="email"
                                            name="email"
                                            class="form-control from_input"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                        >
                                        <div
                                            *ngIf="submitted && f.email.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.email.errors.required">Please enter your email address</div>
                                            <div *ngIf="f.email.errors.pattern">Please enter a valid email address</div>
                                        </div>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="icon-lock"></i>
                                            </span>
                                        </div>
                                        <input
                                            type="password"
                                            formControlName="password"
                                            id="password"
                                            name="password"
                                            class="form-control from_input"
                                            placeholder="Password"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                        >
                                        <div
                                            *ngIf="submitted && f.password.errors"
                                            class="invalid-feedback"
                                        >
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button
                                                type="submit"
                                                class="btn btn-primary px-4"
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <!-- <div class="col-6 text-right">
                                            <button
                                                type="button"
                                                class="btn btn-link px-0"
                                            >
                                                Forgot password?
                                            </button>
                                        </div> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
