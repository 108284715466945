<div id="header" class="header">
  <div class="content-body flow-rooX3">
  <div class="content-header">
          <div class="page-head">
              <h1>{{pageTitle}}</h1>
              <div class="breadcrumbs">
                <!-- <app-ng-dynamic-breadcrumb [bgColor]="'#f5f7fa'" [fontSize]="'14px'" [symbol]="'/'"></app-ng-dynamic-breadcrumb> -->
              </div>
          </div>
          <ul class="notification-container">
              <!-- <li><a><span class="material-icons-two-tone">notifications</span></a><div class="n-counter">12</div></li> -->
              <!-- <li>
                  <div class="dropdown-noti accord-hassubmenubtn">
                      <div class="dropbtn-noti">
                          <span class="n-counter">12</span><span class="material-icons-two-tone">notifications</span>
                          <div id="notificationDropdown" class="dropdown-content-noti accord-hassubmenutarget">
                              <div class="notification-block">
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                                  <a>
                                      <div class="notification">
                                          <figure><img src="assets/img/profile.png"></figure>
                                          <article>
                                              <h4>Hardik Ajmeri has Viewed your Profile</h4>
                                              <p>09 Mar 2022</p>
                                          </article>
                                      </div>
                                  </a>
                              </div>
                              <div class="notification__footer">
                                  <a>View all Notifications</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </li> -->
              <!-- <li><a><span class="material-icons-two-tone">logout</span></a></li> -->
          </ul>
      </div>
  </div>
</div>
