<div class="sidebar">
  <div class="sidebar-header">
      <div class="top-header">
          <a class="sidebar-logo"><img src="assets/img/logo-icon.png"></a>
          <a class="sidebar-logo-text"><img src="assets/img/logo-text.png"></a>
          <div class="ham__menu" id="contentMenu">
              <a id="mobileMenu" class="content-menu d-lg-none"></a>
          </div>
      </div>
      <div class="profile">
          <figure><img [src]="profileDate?.img"></figure>
          <article routerLink="/profile">
              <h2>{{profileDate?.name}}</h2>
              <p>{{profileDate?.speciality}}</p>
          </article>
      </div>
  </div><!-- sidebar-header -->
  <div class="sidebar-body">
      <ul class="nav-sidebar">
        <ng-container  *ngFor="let item of navitem">
          <li class="nav-item"><a class="nav-link {{item.children?'with-sub':''}}" (click)="parentNavigate(item)"><span class="material-icons-two-tone">{{item.icon}}</span><span>{{item.name}}</span></a>
              <nav class="nav-sub" *ngIf="item.children">
                <ng-container  *ngFor="let child of item.children">
                <a class="sub-link" [routerLink]="child.url">{{child.name}}</a>
              </ng-container>
              </nav>
          </li>
        </ng-container>

      </ul>
  </div><!-- sidebar-body -->
</div>
