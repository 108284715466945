import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationPromptComponent } from './components/confirmation-prompt/confirmation-prompt.component';
import { ThumbnailViewComponent } from './components/thumbnail-view/thumbnail-view.component';
import { BaseComponent } from './components/base.component';



@NgModule({
  declarations: [
    ThumbnailViewComponent,
    BaseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ThumbnailViewComponent,
    BaseComponent
  ]
})
export class SharedModule { }
