import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class WebService {
  token: any;
  headersObj: any;
  options: any;
  constructor(private _http: HttpClient) {}
  getHeader() {
    // this.token = localStorage.getItem("token");
    this.headersObj = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    //.set("Authorization", 'Bearer ' + this.token);
    let header = {
      headers: this.headersObj,
      withCredentials: true,
      // responseType: 'text' as 'json'
    };
    return header;
  }

  getHeaderResponseText() {
    // this.token = localStorage.getItem("token");
    this.headersObj = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    //.set("Authorization", 'Bearer ' + this.token);
    let header = {
      headers: this.headersObj,
      withCredentials: true,
      responseType: 'text' as 'json',
    };
    return header;
  }
  getFileHeader() {
    this.token = localStorage.getItem('token');
    this.headersObj = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    let header = {
      headers: this.headersObj,
    };
    return header;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }
  get(url: any): Observable<any> {
    return this._http
      .get(environment.API_ENDPOINT + url, this.getHeader())
      .pipe(catchError(this.handleError));
  }
  post(url: any, data: any): Observable<any> {
    return this._http
      .post(environment.API_ENDPOINT + url, data, this.getHeader())
      .pipe(catchError(this.handleError));
  }
  checklogin(data: any, url: any) {
    return this._http
      .post(environment.API_ENDPOINT + url, data)
      .pipe(catchError(this.handleError));
  }
  login(data: any, url: any) {
    return this._http
      .post(environment.API_ENDPOINT + url, data)
      .pipe(catchError(this.handleError));
  }
  fileUpload(url: any, data: any): Observable<any> {
    return this._http.post(
      environment.API_ENDPOINT + 'cpanel-api/' + url,
      data,
      {
        headers: {
          Accept: 'application/json',
        },
        withCredentials: true,
        responseType: 'text',
      }
    );
  }
  filePatchUpload(url: any, data: any): Observable<any> {
    return this._http.patch(
      `${environment.API_ENDPOINT}cpanel-api/${url}`,
      data,
      {
        headers: {
          Accept: 'application/json',
        },
        withCredentials: true,
        responseType: 'text',
      }
    );
  }
  videoUpload(URL: any, data: any): Observable<any> {
    return this._http
      .post(environment.API_ENDPOINT + URL, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.handleError));
  }
  fileUploadPut(URL: any, data: any): Observable<any> {
    return this._http
      .put(environment.API_ENDPOINT + URL, data, this.getFileHeader())
      .pipe(catchError(this.handleError));
  }
  delete(url: any): Observable<any> {
    return this._http
      .delete(environment.API_ENDPOINT + url, this.getHeader())
      .pipe(catchError(this.handleError));
  }
  put(url: any, data: any): Observable<any> {
    return this._http
      .put(environment.API_ENDPOINT + url, data, this.getHeader())
      .pipe(catchError(this.handleError));
  }

  patch(url: any, data: any): Observable<any> {
    return this._http
      .patch(environment.API_ENDPOINT + url, data, this.getHeader())
      .pipe(catchError(this.handleError));
  }
  patchResponseText(url: any, data: any): Observable<any> {
    return this._http
      .patch(environment.API_ENDPOINT + url, data, this.getHeaderResponseText())
      .pipe(catchError(this.handleError));
  }

  profilePatch(url: any, data: any): Observable<any> {
    return this._http
      .patch(environment.API_ENDPOINT + 'api/v2/' + url, data, this.getHeader())
      .pipe(catchError(this.handleError));
  }
}
