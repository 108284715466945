import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
  ) {

  }

  /* ===============================================================
  JavaScript Load
  =============================================================== */
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../../../assets/js/frontend.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }



  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.loadScript();
      window.scrollTo(0, 0);
    });
  }
}
