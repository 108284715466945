import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './default-layout/default-layout.component';

import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { CampaignProcessorComponent } from './views/campaigns/campaign-processor/campaign-processor.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/profile',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "infocenter",
        loadChildren: () =>
          import("./views/infocenter/infocenter.module").then(
            (m) => m.InfocenterModule
          ),
      },
      {
        path: "content",
        loadChildren: () =>
          import("./views/content/content.module").then(
            (m) => m.ContentModule
          ),
      },
      {
        path: "campaigns",
        loadChildren: () =>
          import("./views/campaigns/campaigns.module").then(
            (m) => m.CampaignsModule
          ),
      },
      {
        path: "community",
        loadChildren: () =>
          import("./views/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'login-trails',
        loadChildren: () => import("./views/user-management/login-trails/login-trails.module").then(m => m.LoginTrailsModule),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./views/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
    ]
  }

 // { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
