<!-- case 1: vertical thumbnail && multiple thumbnail view (image, vimeo,mp4,ytvideo, pdf) -->
<ng-container *ngIf="post?.multimedia?.length > 1 ">
    <!-- vertical thumbnail -->
    <ng-container *ngIf="verticalThumbnailList && showVerticalThumbnail">
        <ng-container *ngFor="let img of verticalThumbnailList">
            <figure>
                <!-- <img class="mt-1" [src]="img.url" alt="No image found" /> -->
                <img  [src]="img.url?.replace('_t', '')"  alt="Post image" />

            </figure>
        </ng-container>
    </ng-container>
    <!-- horizontal thumbnail -->
    <div *ngIf="horizontalThumbnailList && showHorizontalThumbnail"
        [ngClass]="{ 'grid-imgs' : horizontalThumbnailList?.length > 3 }">
        <ng-container *ngFor="let img of horizontalThumbnailList">
            <figure>
                <div class="__image">
                    <!-- <img class="class="mt-1"mt-1" [src]="img.url" alt="No image found" /> -->
                    <img  [src]="img.url?.replace('_t', '')" alt="Post image" />

                </div>
            </figure>
        </ng-container>
    </div>
   
    <!-- PDF -->
    <div *ngIf="contentDocumentList">
        <ng-container *ngFor="let img of contentDocumentList">
            <figure>
                <div class="__image">
                    <iframe  width="100%" height="100%" [src]="img" frameborder="0"></iframe>
                </div>
            </figure>
        </ng-container>
    </div>
</ng-container>

<!-- case:2 singal thumbnail (no vertical thumbnail & multiple thumbnail) -->
<ng-container *ngIf="post?.multimedia?.length == 1">

    <figure>
        <ng-container
            *ngIf="post.multimedia[0].contentType == 'image/jpg' || post.multimedia[0].contentType == 'image/png' || post.multimedia[0].contentType == 'image/jpeg' && post.multimedia[0].featured == true">
            <div class="__image">
                <img [src]="post.multimedia[0].url.replace('_t', '')"/>
            </div>
        </ng-container>

        <!-- PDF in post thumbnail -->
        <ng-container *ngIf="sourceUrl != null">
            <div class="__image">
                <iframe width="100%" height="100%" [src]="sourceUrl" frameborder="0"></iframe>
            </div>
        </ng-container>
    </figure>
</ng-container>

<!-- case 3 : no thumbnail && embedURL -->
<ng-container *ngIf="post?.multimedia?.length == 0 && post.embedURL != null">
    <figure>
        <ng-container *ngIf="post.embedURL">
            <ng-container *ngIf="get_url_extension(post.embedURL) === 'pdf'">
                <div class="__image">
                    <iframe width="100%" height="100%" [src]="pdfUrl"  frameborder="0"></iframe>
                </div>
            </ng-container>
        </ng-container>
    </figure>
</ng-container>
