import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from "../../services/shared.service";
@Component({
  selector:  'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
pageTitle:string='';
  constructor(
    private sharedService: SharedService,
    public router: Router,
    public activatedRoute: ActivatedRoute,) {

       this.sharedService.sharedPageTitle.subscribe((data: any) => {
         console.log('page title');
         
        console.log({data});
        this.pageTitle=data;
      });
      // activatedRoute.params.subscribe(val => {
      //   this.sharedService.updatePageTitle();
      // });
   }

  ngOnInit(): void {
  }

}
